import { Injectable } from '@angular/core';
import { CONFIG } from '@proman/config';
import { RequestService } from '@proman/services/request.service';
import { isDefinedNotNull } from '@proman/utils';

@Injectable()
export class PrinterService {

    constructor(
        private Request: RequestService,
    ) {

    }

    /*printZPL = function(zpl: any) {
        const UTF8ToBase64 = (content: any) => {
            return window.btoa(unescape(encodeURIComponent(content)));
        };

        const downloadContent = (content: any) => {
            let a = document.createElement('a');
            let event;

            a.download = 'barcode.zpl';
            a.href = 'data:application/octet-stream;base64,' + UTF8ToBase64(content);
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(event);
        };

        return downloadContent(zpl);
    };*/

    printImage = (src: string) => {
        return this.printHtml(`<img src="${src}"/>`);
    };

    printHtml = (body: any) => {
        return this.print(`<html><head><title>Print</title></head><body>${body}</body></html>`);
    };

    printLayout = (path: string) => {
        let url = CONFIG.api + 'print/' + path;

        return this.print(this.Request.get(url)
            .then((response: any) => response.data));
    };

    print = (content: any, type: string = 'html') => {
        const printXml = (content: any, type: string) => {
            const file = new Blob([content], { type: `text/${type}` }); // type xml/csv only
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = url.split('/').pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        if (type === 'xml' || type === 'csv') {
          if (content.then) {
            content.then((content: any) => printXml(content, type), (error: any) => {});
          } else {
            printXml(content, type);
          }
        } else {
          let w = window.open('', 'print');

          const print = (content: any) => {
            if (isDefinedNotNull(w)){
              w.document.write(content);
              setTimeout(() => {
                w.print();
                w.close();
              }, 250);
            }
          };

          if (content.then) {
            content.then((content: any) => print(content), (error: any) => w.close());
          } else {
            print(content);
          }
        }
    };

    // printXml = (content: any) => {
    //     let xmlDoc = $.parseXML(content);
    //     let serializer = new XMLSerializer();
    //
    //     return this.print(serializer.serializeToString(xmlDoc.getRootNode()), false);
    // }

}
